import create from 'zustand'
import {persist} from "zustand/middleware"

export const useNotes = create(persist(
    (set, get) => ({
        notes: "",
        setNotes: (notes) => set({
            notes: notes,
        }),
    }),
    {
        name: "notes", // unique name
        getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
))

