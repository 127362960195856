import React, {useContext, useEffect, useState} from 'react';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import axios from 'axios';
import {toast, Toaster} from 'react-hot-toast';
import {useHistory} from "react-router-dom";
import {useContact} from "../GlobalStorage/contactInformationState";
import {getBasketTotal, useBasket} from "../GlobalStorage/warenkorbState";
import {useNotes} from "../GlobalStorage/notes";
import {useDiscount} from "../GlobalStorage/discount";
import {useDeliveryInformationStore} from "../GlobalStorage/deliveryInformationState";
import {getClientSecret, setPaymentAsSucc} from "../Firebase/paymentFunction";
import {AuthContext} from "../Firebase/firebaseAuthProvider";

const selectorContact = state => [state.contactInformation, state.addInformation]
const selector = state => [state.basket, state.emptyBasket]
const selectorNotes = state => [state.notes, state.setNotes]
const discountSelector = state => [state.discountInformation, state.clearDiscountInfos]
const selectorDeliveryInformation = state => [
  state.pickupDate,
  state.dropOffDate,
  state.pickupTimeFrame,
  state.dropOffTimeFrame,
  state.pickupRef,
  state.dropOffref,
  state.pickupRegionID,
  state.dropOffRegionID,
]

export default ({onAbort, customer}) => {
  const pkLive = 'pk_live_51ISLv8CllkC8pWneRXoF7h09qVadMcRAv2QC9tlYiD4S4OxfBYwt4qJDrW8FeYpS2STZfg1iMWf8NR7FOF8rLaU300a1GSwhaF';
  const liveURL = 'https://us-central1-droov-fbaef.cloudfunctions.net/payment/payment-intents';

  const pkTest = 'pk_test_51ISLv8CllkC8pWneek04xl3Z2qH3i1wBaz7snxpptSmdDGsikuAqzMcvZoWP1utbvqcOzLLZf9T8vBdC7sgNtrkI00G4TXrd5f';
  const testURL = 'https://us-central1-droov-fbaef.cloudfunctions.net/testStripePayment/payment-intents';

  const stripePromise = loadStripe(pkLive);

  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState();
  const [orderID, setOrderID] = useState();
  const [paymentIntentID, setPaymentIntentID] = useState();

  const history = useHistory()

  const [contactInformation] = useContact(selectorContact)
  const [basket, emptyBasket] = useBasket(selector)
  const [notes, setNotes] = useNotes(selectorNotes)
  const [processing, setProcessing] = useState(false);
  const [discountInformation, clearDiscountInfos] = useDiscount(discountSelector)
  const [pickupDate,
    dropOffDate,
    pickupTimeFrame,
    dropOffTimeFrame,
    pickupRef,
    dropOffref,
    pickupRegionID,
    dropOffRegionID,
  ] = useDeliveryInformationStore(selectorDeliveryInformation)
  const {userInformation, user} = useContext(AuthContext);

  const cancelPaymentIntent = () => {
    if (paymentIntentID == null) {
      return;
    }
    return axios.delete(liveURL, {
      data: {
        paymentIntentId: paymentIntentID,
        orderId: orderID
      },
    });
  };

  useEffect(() => {
    return () => {
      if (paymentIntentID) {
        cancelPaymentIntent().catch((error) =>
          console.log(`Cancel Payment Intent Error: ${error}`)
        );
      }
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      console.log("Use effect calling in Stripe Payment Component JS")
      setIsLoading(true);
      try {
        const response = await getClientSecret(basket, {
          pickupDate,
          dropOffDate,
          pickupTimeFrame,
          dropOffTimeFrame,
          pickupRef,
          dropOffref,
          pickupRegionID,
          dropOffRegionID,
        }, contactInformation, userInformation, customer, notes, "Credit Card", discountInformation.discountCode)

        console.log("RESPONSE STATUS CAME: " + response.status)

        if (!response.data.clientSecret) {
          console.log("Cannot get client Secret")
          return
        }

        if (response.status === 201) {
          console.log("Get client secret response: SUCCESS")
          console.log(response.data)
          setClientSecret(response.data.clientSecret);
          setOrderID(response.data.orderID);
          setPaymentIntentID(response.data.paymentIntent);
        } else {
          toast.error(
            "Sorry, we're having trouble creating your payment order. Please try again."
          );
        }
      } catch (error) {
        setIsLoading(false);
        toast.error(error);
      }
      //setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div className='flex flex-col items-center gap-4'>
      <Toaster/>
      {clientSecret && (
        <div className={isLoading ? 'hidden' : ''}>
          <Elements
            stripe={stripePromise}
            options={{clientSecret: clientSecret}}
          >
            <CheckoutForm
              onReady={() => setIsLoading(false)}
            />
          </Elements>
        </div>
      )}
      {isLoading && (
        <div className='flex justify-center items-center'>
          <div className='loader'>Loading</div>
        </div>
      )}
      <button
        type='abort'
        className='text-sm font-medium text-gray-500'
        onClick={() => {
          console.log('Payment abort');
          cancelPaymentIntent();
          onAbort();
        }}
      >
        Zurück
      </button>
    </div>
  );
};

const CheckoutForm = ({onReady}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [basket, emptyBasket] = useBasket(selector)
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!stripe || !elements) {
      return;
    }

    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/success`,
      },
    })

    setIsLoading(false);
    if (error) {
      setErrorMessage(error.message);
    }
    // Unreachable code if successful
    // TODO: Call clearing of states on Success page
    /*window.dataLayer.push({
      'event': 'thank_you',
      'conversionValue': getBasketTotal(basket),
    });*/
  };

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isLoading]);

  return (
    <form onSubmit={handleSubmit} className='flex flex-col items-center gap-4'>
      {isLoading && (
        <div class='loadingOverlay'>
          <div class='loader'></div>
        </div>
      )}

      <PaymentElement
        onReady={() => onReady?.()}
        className='w-full mt-2 sm:w-100'
      />
      {errorMessage && (
        <p className='text-red-500 w-full sm:w-100 outline-none mt-2 text-center items-center m-auto justify-center'>
          {errorMessage}
        </p>
      )}
      <button
        type='submit'
        disabled={!stripe}
        className='myButton my-6'
      >
        Bezahlen
      </button>
    </form>
  );
};
