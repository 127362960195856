import create from 'zustand'
import {persist} from "zustand/middleware"

export const useDeliveryInformationStore = create(persist(
    (set, get) => ({
        lastSet: "",
        pickupDateIndex: -1,
        dropOffDateIndex: -1,
        pickupTimeFrameIndex: -1,
        dropOffTimeFrameIndex: -1,
        pickupDate: null,
        dropOffDate: null,
        pickupTimeFrame: null,
        dropOffTimeFrame: null,
        pickupRef: "",
        dropOffref: "",
        pickupRegionID: "",
        dropOffRegionID: "",
        resetAll: () => set({
            lastSet: "",
            pickupDateIndex: -1,
            dropOffDateIndex: -1,
            pickupTimeFrameIndex: -1,
            dropOffTimeFrameIndex: -1,
            pickupDate: null,
            dropOffDate: null,
            pickupTimeFrame: null,
            dropOffTimeFrame: null,
        }),
        setTimeStamp: (timeStamp) => set({lastSet: timeStamp}),
        setPickupDateIndex: (index, date) => set({
            pickupDateIndex: index,
            pickupTimeFrameIndex: -1,
            dropOffDateIndex: -1,
            dropOffTimeFrameIndex: -1,
            dropOffDate: null,
            dropOffTimeFrame: null,
            pickupTimeFrame: null,
            pickupDate: date,

        },),
        setDropOffDateIndex: (index, date) => set({
            dropOffDateIndex: index,
            dropOffTimeFrameIndex: -1,
            dropOffDate: date,
            dropOffTimeFrame: null,
        },),
        setPickupTimeFrameIndex: (index, frame, regionID, tourRef) => set({
            pickupTimeFrameIndex: index,
            pickupTimeFrame: frame,
            pickupRef: tourRef,
            pickupRegionID: regionID
        }),
        setDropOffTimeFrameIndex: (index, frame, regionID, tourRef) => set({
            dropOffTimeFrameIndex: index,
            dropOffTimeFrame: frame,
            dropOffref: tourRef,
            dropOffRegionID: regionID
        }),
    }),
    {
        name: "droove_delivery_information", // unique name
        getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
))

