import TimeCarusell from "../Components/TimeCarusell";
import TimeSlotGrid from "../Components/TimeSlotGrid";
import {useTourInformation} from "../Firebase/useTourInformation"
import {useContact} from "../GlobalStorage/contactInformationState";
import {useDeliveryInformationStore} from "../GlobalStorage/deliveryInformationState"
import {useHistory} from "react-router-dom"
import {useEffect} from "react"
import {confirmPage} from "../routesConfig";
import {analytics} from "../analytics";

const selectorContact = state => [state.contactInformation]
const selectorDeliveryInformation = state => [
    state.pickupDateIndex,
    state.dropOffDateIndex,
    state.pickupTimeFrameIndex,
    state.dropOffTimeFrameIndex,
    state.setPickupDateIndex,
    state.setDropOffDateIndex,
    state.setPickupTimeFrameIndex,
    state.setDropOffTimeFrameIndex,
    state.setTimeStamp,
    state.lastSet,
    state.resetAll,
]

function DeliveryTimeStotsPage() {
    const [
        pickupDateIndex,
        dropOffDateIndex,
        pickupTimeFrameIndex,
        dropOffTimeFrameIndex,
        setPickupDateIndex,
        setDropOffDateIndex,
        setPickupTimeFrameIndex,
        setDropOffTimeFrameIndex,
        setTimeStamp,
        lastSet,
        resetAll
    ] = useDeliveryInformationStore(selectorDeliveryInformation)

    const [contactInformation] = useContact(selectorContact)
    const [nextTwoWeeks] = useTourInformation(contactInformation.zipcode)
    const [nextTwoDropOffWeeks] = useTourInformation(contactInformation.zipcode, nextTwoWeeks[pickupDateIndex])

    const canClickNext = dropOffDateIndex !== -1 && pickupDateIndex !== -1 && pickupTimeFrameIndex !== -1 && dropOffTimeFrameIndex !== -1

    const history = useHistory()

    function handleNext() {
        if (canClickNext) {
            let now = new Date()
            setTimeStamp(now)
            history.push(confirmPage)
        }
    }


    useEffect(() => {

        const now = new Date()
        const minutes = ((now - lastSet)/60000)
        if (minutes>30){
            resetAll()
        }
        // eslint-disable-next-line
        // Google Tag Manager (For Kingsgard tracking of customer steps)
        analytics(window, document, 'script', 'dataLayer', 'abholzeit', 0);
        /*window.dataLayer.push({'event': 'abholzeit'});*/
    }, []);


    return (
        <div className="max-w-lg mx-auto">{

            nextTwoWeeks.length > 0 ?
                <div>
                    <h1 className="myTitle">Abholzeit wählen</h1>
                    <br/>
                    <TimeCarusell type="pickup" activeDate={pickupDateIndex} dateArray={nextTwoWeeks || []}
                                  onDaySelected={(index, date) => setPickupDateIndex(index, date.date)}/>
                    <br/>
                    <TimeSlotGrid onSelectedTimeFrame={(index, frame, regionID, tourRef) => setPickupTimeFrameIndex(index, frame, regionID, tourRef)}
                                  activeIndex={pickupTimeFrameIndex}
                                  timeSlots={pickupDateIndex === -1 ? [] : nextTwoWeeks[pickupDateIndex]?.timeFrame}/>
                    <br/>
                    <br/>
                    <div className="border-b">
                    </div>
                    <br/>
                    <br/>
                    {
                        pickupTimeFrameIndex !== -1 &&
                        <div>
                            <h1 className="myTitle">Liefertermin wählen</h1>
                            <br/>
                            <TimeCarusell activeDate={dropOffDateIndex} type="return"
                                          onDaySelected={(index, date) => setDropOffDateIndex(index, date.date)}
                                          dateArray={nextTwoDropOffWeeks || []}/>
                            <br/>
                            <TimeSlotGrid onSelectedTimeFrame={(index, frame, regionID, tourRef) => setDropOffTimeFrameIndex(index, frame, regionID, tourRef)}
                                          activeIndex={dropOffTimeFrameIndex}
                                          timeSlots={dropOffDateIndex === -1 ? [] : nextTwoDropOffWeeks[dropOffDateIndex]?.timeFrame}/>
                        </div>
                    }


                    <br/>
                    <br/>
                    <button disabled={!canClickNext} onClick={() => handleNext()}
                            className={`myButton md:mt-10 bottom-0 ${!canClickNext && "opacity-50"}`}>Weiter
                    </button>
                </div>
                :
                <div className="loader"/>


        }


        </div>
    );
}

export default DeliveryTimeStotsPage;


