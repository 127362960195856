const TimeSlotGrid = ({timeSlots, onSelectedTimeFrame, activeIndex}) => {
    return (
        <div className="grid gap-6 md:grid-cols-3  ">
            {timeSlots?.map(({frame, regionID, tourRef}, index) => <TimeSLotChip active={index === activeIndex}
                                                                                 onClicked={() => onSelectedTimeFrame(index, frame, regionID, tourRef)}
                                                                                 key={index}
                                                                                 timeSlot={frame}/>)}
        </div>
    );
};

const TimeSLotChip = ({timeSlot, onClicked, active}) => {
    const activeChip = active ? "bg-blue-50 border-blue-500" : ""
    return (
        <div
            onClick={() => onClicked()}
            className={"hover:bg-blue-50 cursor-pointer border flex justify-center items-center rounded-xl xl:text-gray-600  py-3 " + activeChip}>
            {active &&
            <svg xmlns="http://www.w3.org/2000/svg" width="20px" className="mr-1 text-blue-500" fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7"/>
            </svg>} {timeSlot}
        </div>
    )
}


export default TimeSlotGrid
