import styled from "styled-components"

const SearchStyle = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;

`


export default function Search({onSearched}) {

    return (
        <SearchStyle>
            <h1 className="myTitle  hidden md:block">Bestellung aufgeben</h1>
            <div className=" w-full ">
                <div className="relative text-gray-600 focus-within:text-gray-400">
                      <span className="absolute inset-y-0 left-2 flex items-center pl-2">
                        <button type="submit" className="p-1 focus:outline-none ">
                          <svg fill="none" stroke="gray"
                               viewBox="0 0 24 24" className="w-6 h-6">
                              <path
                                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                          </svg>
                        </button>
                      </span>
                    <input type="search" name="q"
                           onChange={(e) => onSearched(e.target.value)}
                           className="w-full px-2 py-4 text-sm bg-blue-50 rounded rounded-2xl pl-12 focus:outline-none focus:text-gray-900"
                           placeholder="Suchen..." autoComplete="off"/>

                </div>
            </div>


        </SearchStyle>
    );

}





