import Basket from "./Basket"
import {useLocation} from "react-router-dom";
import {products, authPage} from "../routesConfig";


const SideBar = ({onNextClicked}) => {
    const location = useLocation();
    return (
        <div className="relative right-0 h-full hidden lg:block px-10 neon__shadow overflow-auto" style={{
        }}>
            <br/>
            < Basket displayDelivery>
                {(location.pathname ===products || location.pathname === authPage) &&
                <button onClick={() => onNextClicked()} className="myButton">Weiter</button>}
            </Basket>
        </div>

    );
};

export default SideBar
