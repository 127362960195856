import './App.css';
import {useState, useContext} from "react"
import Navbar from "./Components/Navbar"
import Layout from "./Components/Layout"
import ConfirmationAndPayPage from "./Views/ConfirmationAndPayPage";
import {
    Redirect,
    useHistory,
    Route,
    Switch
} from "react-router-dom";
import SideBar from "./Components/SideBar";
import ProductsPage from "./Views/ProductsPage";
import AdressPage from "./Views/AdressPage";
import AuthPage, {LoginPage, ResetPage, ResetSend} from "./Views/AuthPage"
import DeliveryTimeSlotsPage from "./Views/DeliveryTimeSlotsPage";
import SuccessPage from "./Views/SuccessPage";
import FailurePage from "./Views/FailurePage";
import {
    authPage,
    loginPage,
    addressPage,
    products,
    delivery,
    confirmPage,
    passwordReset, resetSend
} from "./routesConfig"
import {AuthContext} from "./Firebase/firebaseAuthProvider";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-NMVD2Z7'
}

TagManager.initialize(tagManagerArgs);

function App() {
    const [currentRouteIndex, setCurrentRouteIndex] = useState(0);
    const history = useHistory()

    const {user, loadingAuthState} = useContext(AuthContext)

    function changeRoute() {
        setCurrentRouteIndex(currentRouteIndex + 1)
        history.push(Routes[currentRouteIndex])
    }

    const Routes = [addressPage, delivery, confirmPage]
    return (
        <div>
            <Switch>
                <Route path="/failure">
                    <FailurePage/>
                </Route>
                <Route path="/success">
                    <Navbar/>
                    <SuccessPage/>
                </Route>
                <Route exact path={confirmPage}>
                    <Navbar/>
                    <ConfirmationAndPayPage/>
                </Route>
                <Route>
                    <div className="lg:grid md:grid-cols-2 lg:grid-cols-3 gap-1">
                        <div className="md:col-span-1 lg:col-span-2">
                            <Navbar/>
                            <br/>
                            <Layout>
                                <Switch>
                                    <Route exact path={products}>
                                        <ProductsPage/>
                                    </Route>
                                    <Route path={addressPage}>
                                        <AdressPage/>
                                    </Route>
                                    {/*<Route path={authPage}>
                                        {
                                            user ?
                                                <Redirect to={confirmPage}/>
                                                :
                                                <AuthPage/>
                                        }
                                    </Route>
                                    <Route path={loginPage}>
                                        {
                                            user ?
                                                <Redirect to={confirmPage}/>
                                                :
                                                <LoginPage/>
                                        }
                                    </Route>
                                    <Route path={passwordReset}>
                                        {
                                            user ?
                                                <Redirect to={confirmPage}/>
                                                :
                                                <ResetPage/>
                                        }
                                    </Route>
                                    <Route path={resetSend}>
                                        {
                                            user ?
                                                <Redirect to={confirmPage}/>
                                                :
                                                <ResetSend/>
                                        }
                                    </Route>*/}
                                    <Route path={delivery}>
                                        <DeliveryTimeSlotsPage/>
                                    </Route>
                                </Switch>
                            </Layout>
                        </div>
                        <div className="bg-white  md:col-span-1 fixed right-0 h-full overflow-auto neon__shadow ">
                            < SideBar onNextClicked={() => changeRoute()}/>
                        </div>
                    </div>
                </Route>
            </Switch>
        </div>
    );
}

export default App;
