import {useContact} from "../GlobalStorage/contactInformationState";
import {useDeliveryInformationStore} from "../GlobalStorage/deliveryInformationState";
import {formatDate} from "../Utils/DateFormatter"

const selectorContact = state => [state.contactInformation, state.addInformation]
const selectorDeliveryInformation = state => [
    state.pickupDate,
    state.dropOffDate,
    state.pickupTimeFrame,
    state.dropOffTimeFrame
]

export default function ExtendedDeliveryInformation() {
    const [contactInformation] = useContact(selectorContact)
    const [pickupDate,
        dropOffDate,
        pickupTimeFrame,
        dropOffTimeFrame] = useDeliveryInformationStore(selectorDeliveryInformation)
    return (
        <div>
            <div className="flex justify-between">
                <h1 className="text-2xl text-gray-800">Lieferung</h1>
                <a className="text-gray-700 flex justify-center items-center " href="/adress">Editieren
                    <svg xmlns="http://www.w3.org/2000/svg"
                         className="w-5 ml-2"
                         fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                    </svg></a>
            </div>
            <br/>
            <p className="myCaption mb-2">Lieferadresse</p>
            <p className="text-gray-500">{contactInformation?.name}
            </p>
            <p className="text-gray-500">{contactInformation?.street} {contactInformation?.housenumber}</p>
            <p className="text-gray-500">{contactInformation?.zipcode}, {contactInformation?.city}</p>
            <p className="text-gray-500">{contactInformation?.telephone}</p>

            <br/>

            {(contactInformation?.notes !== "") ? (
                <div>
                    <p className="myCaption mb-2">Lieferhinweise</p>
                    <p className="text-gray-500">{contactInformation?.notes}
                    </p>
                    <br/>
                </div>
            ) : (
                <></>
            )}

            {contactInformation?.differentInvoiceAddress ? (
                <div>
                    <p className="myCaption mb-2">Rechnungsadresse</p>
                    <p className="text-gray-500">{contactInformation?.invoiceCompanyName}</p>
                    <p className="text-gray-500">{contactInformation?.invoiceName}</p>
                    <p className="text-gray-500">{contactInformation?.invoiceStreet} {contactInformation?.invoiceHousenumber}</p>
                    <p className="text-gray-500">{contactInformation?.invoiceZipcode}, {contactInformation?.invoiceCity}</p>
                    <p className="text-gray-500">{contactInformation?.invoiceCountry}</p>
                </div>
            ) : (
                <></>
            )}

            {/*<img src={MapImage} alt="" className=""/> removing until better days when we implement mapping*/}
            <br/>
            <br/>
            <div className="flex justify-between">
                <div >
                    <h1 className="uppercase text-md text-gray-800 aligh__text__start">Abholtag</h1>
                    <p className="text-gray-500 aligh__text__end aligh__text__start">{formatDate(pickupDate)}</p>
                </div>
                <div>
                    <h1 className="uppercase text-md text-gray-800 aligh__text__end">ABHOLZEIT</h1>
                    <p className="text-gray-500 aligh__text__end" >{pickupTimeFrame}</p>
                </div>

            </div>
            <br/>
            <div className="flex justify-between">
                <div>
                    <h1 className="uppercase text-md text-gray-800 aligh__text__end">Rückgabetag</h1>
                    <p className="text-gray-500">{formatDate(dropOffDate)}</p>
                </div>
                <div>
                    <h1 className="uppercase text-md text-gray-800 aligh__text__end">Rückgabezeit</h1>
                    <p className="text-gray-500 aligh__text__end">{dropOffTimeFrame}</p>
                </div>

            </div>

        </div>
    )
}