import Search from "../Components/Search";
import Carusell from "../Components/Carusell";
import Product from "../Components/Product";
import {useProducts} from "../Firebase/useProducts"
import {useState, useEffect} from "react"
import Drawer from '../Components/react-bottom-drawer'
import Basket from "../Components/Basket"
import {useHistory} from "react-router-dom"
import {addressPage} from "../routesConfig";
import useGtmEvent from '../useGtmEvent';
import {analytics} from "../analytics";

function ProductsPage() {

    const history = useHistory()
    const [categoriesWithItems, loading] = useProducts()
    const [selectedCategory, setSelectedCategory] = useState(-1);
    const [filteredItems, setFilteredProducts] = useState([]);
    const [drawerOpened, setDrawerOpened] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const { pushToDataLayer } = useGtmEvent();

    useEffect(() => {
      // filter if new items arrive
      analytics(window, document, 'script', 'dataLayer', 'bestellung', 0);
    }, []);

    useEffect(() => {
        // filter if new items arrive
        let filteredCatList = categoriesWithItems.filter((category, index) => category.order === selectedCategory)
        setFilteredProducts(filteredCatList[0]?.items || [])
    }, [selectedCategory, categoriesWithItems]);

    useEffect(() => {
        if(!searchTerm){
            let filteredCatList = categoriesWithItems.filter((category, index) => category.order === selectedCategory)
            setFilteredProducts(filteredCatList[0]?.items || [])
            return
        }
        const filteredList = searchOverItems(searchTerm)
        setFilteredProducts(filteredList)
    }, [searchTerm]);


    const searchOverItems = (searchTerm) => {
        let filteredItems = []
        categoriesWithItems.forEach((categorie) => {
            const filteredList = containedItems(searchTerm, categorie.items)
            filteredItems = [...filteredItems, ...filteredList]
        })

       return filteredItems
    }

    const containedItems = (text, items) => {
       const filteredList =  items.filter((item)=>item.displayName.toLowerCase().includes(text.toLowerCase()))
        return filteredList
    }

    useEffect(() => {
        // set category initial to first item in the array if new items arrive
        if (categoriesWithItems.length > 0) {
            setSelectedCategory(categoriesWithItems[0].order)
        }
    }, [categoriesWithItems]);

    return (
        <div>
            <br/>
            <Search onSearched={(term)=>setSearchTerm(term)}/>
            <br/>
            <br/>
            <Carusell categories={categoriesWithItems} selectedCategory={(order) => setSelectedCategory(order)}/>
            <br/>

            {
                loading ? <div className="loader">Loading</div> :
                    <div>


                        {
                            filteredItems.length === 0 ? <div className="flex justify-center items-center">
                                EMPTY
                            </div> : filteredItems?.map((item) =>
                                <Product id={item.id} catID={item.catID} imageURL={item.imageUrl}
                                         title={item.displayName}
                                         price={item.price}
                                         description={item.description} key={item.id}/>)
                        }


                    </div>
            }
            <button
                className="fixed bottom-5 right-5 bg-gray-800 p-5 rounded-full noSelect droov_shadow lg:hidden z-10"
                onClick={() => setDrawerOpened(true)}>
                <svg className="h-6 w-6 text-white focus:outline-none" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke="currentColor">
                    <path className="focus:outline-none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                          d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"/>
                </svg>
            </button>
            <Drawer isVisible={drawerOpened} onClose={() => setDrawerOpened(false)}>
                <div style={{zIndex: "99"}}>
                    <Basket deliveryDisabled>
                        <button onClick={() => history.push(addressPage)} className="myButton">Weiter</button>
                    </Basket>
                    <br/>
                </div>
            </Drawer>

        </div>
    );
}

export default ProductsPage;