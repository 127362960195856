const WochenTage = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]

export function getFormattedDate(date, timeFrame) {
    /* if (!state.deliveryInformation) {
         return ""
     }*/
    const parsedDate = new Date(date)
    const wochenTag = parsedDate.getDay()
    const Datum = parsedDate.getDate() + "." + (parsedDate.getMonth() + 1)

    const timeFrameString = timeFrame ? timeFrame : ""
    return WochenTage[wochenTag] + " " + Datum + ", " + timeFrameString
}


export function formatDate(date) {
    const parsedDate = new Date(date)
    const wochenTag = parsedDate.getDay()
    const Datum = parsedDate.getDate()

    return WochenTage[wochenTag] + " " + Datum + "." + (parsedDate.getMonth() + 1) + "." + parsedDate.getFullYear()

}