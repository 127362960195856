import db from "./firebaseInit"
import {useEffect, useState} from "react";

export function useProducts() {

    const [categoriesWithItems, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        getStuff()
    }, []);

    async function getStuff() {
        const categories = await fetchCatalogue()
        const sortedItems = categories.sort((a, b) => (a.order - b.order))
        setItems(sortedItems)
        setLoading(false)
    }


    return [categoriesWithItems, loading]
}


async function fetchCatalogue() {
    let ItemsForCat = []
    const categories = await fetchCategories()
    for (let i = 0; i < categories.length; i++) {
        const items = await fetchItems(categories[i].id)
        ItemsForCat.push({
            catname: categories[i].title,
            catID: categories[i].id,
            imageURL: categories[i].imageUrl,
            items: items,
            order: categories[i].position
        })
    }
    return ItemsForCat

}

async function fetchCategories() {
    let categories = []
    const querySnapshot = await db.collection("catalogue").doc("category").collection("categories").orderBy("position", "asc").get()
    querySnapshot.forEach((doc) => {
        const catWithId = {id: doc.id, ...doc.data()}
        categories.push(catWithId)
    });


    return categories
}

async function fetchItems(id) {
    let items = []
    const queryItemsSnapshot = await db.collection("catalogue").doc("category").collection("categories").doc(id).collection("items").get()
    queryItemsSnapshot.forEach((doc) => {
        if (!doc.data()?.disabled) {
            const itemWithID = {catID: id, id: doc.id, ...doc.data()}
            items.push(itemWithID)
        }
    });

    items = items.sort((a, b) => a?.position - b?.position)
    return items
}

