import styled from "styled-components"
import logo from "../assets/logo_black.png"
import {loginPage, products} from "../routesConfig";
import {useState} from "react";
import {User} from "react-feather";
import {auth} from "../Firebase/firebaseInit";
import {handleLogout} from "../Firebase/firebaseAuthProvider";

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  align-items: center;
  z-index: 98;
`

export default function Navbar() {

    const [showDropDown, setShowDropDown] = useState(false);
    return (
        <HeaderWrapper>

            <a href={products}> <img style={{height: "30px"}} src={logo} alt="logo"/></a>

            <div className="hidden md:flex " style={{alignItems: "center", paddingTop: "5px"}}>
                <a className="mr-14" href="https://reinio.de/geschaeftskunden">Für Firmen</a>
                <a className="mr-14" href="https://reinio.de/kontakt">Kontakt</a>
                <a className="mr-14" href="https://reinio.de/impressum/">Impressum</a>
                {/*<div className="dropdown relative ">
                    <div className="flex justify-end items-center cursor-pointer">
                        <button className="bg-gray-800 mr-4 text-white rounded rounded-xl focus:outline-none p-2">
                            <User/>
                        </button>
                        <p>{auth.currentUser?.email}</p>
                    </div>
                    {
                        auth.currentUser?.email ?
                            <div
                                className="dropdown-menu absolute hidden right-0 w-48 py-2 bg-gray-100 rounded-lg shadow-xl">
                                <a href="#"
                                   onClick={() => handleLogout()}
                                   className="block px-4 py-2 text-gray-800 hover:bg-gray-800 hover:text-white">Abmelden</a>
                            </div> :
                            <div
                                className="dropdown-menu absolute hidden right-0 w-48 py-2 bg-gray-100 rounded-lg shadow-xl">
                                <a href={loginPage}
                                   className="block px-4 py-2 text-gray-800 hover:bg-gray-800 hover:text-white">Anmeldung</a>
                            </div>
                    }
                </div>*/}
            </div>

            <div className="relative inline-block text-left md:hidden">
                <button onClick={() => setShowDropDown(!showDropDown)} className="w-8 md:hidden">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16"/>
                    </svg>
                </button>


                {
                    showDropDown &&

                    <div>
                        <div onClick={() => setShowDropDown(false)}
                             className="bg-gray-700 opacity-50 fixed top-0 right-0 left-0 bottom-0 z-10">

                        </div>

                        <div

                            className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu" aria-orientation="vertical" aria-labelledby="options-menu">

                            <div className="py-1" role="none">
                                <a href="https://reinio.de/geschaeftskunden"
                                   className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                   role="menuitem">Für Firmen</a>
                                <a href="https://reinio.de/kontakt"
                                   className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                   role="menuitem">Kontakt</a>
                                <a href="https://reinio.de/impressum/"
                                   className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                   role="menuitem">Impressum</a>
                                <hr/>
                                {/*<p className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">{auth.currentUser?.email}</p>
                                {auth.currentUser ? <button
                                        onClick={() => handleLogout()}
                                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                        role="menuitem">
                                        Ausloggen
                                    </button> :
                                    <a
                                        href="/login"
                                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                        role="menuitem">
                                        Anmelden
                                    </a>
                                }*/}
                            </div>
                        </div>
                    </div>

                }
            </div>

        </HeaderWrapper>
    );

}