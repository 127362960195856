export const analytics = ((w: Window, d: Document, s: string, l: string, t: string, v:any) => {
  w.dataLayer = window.dataLayer || [];
  if (v > 0) {
    w.dataLayer.push({'event': t, 'value':  v, 'currency': "EUR"});
  } else {
    w.dataLayer.push({'event': t});
  }


  var dl = l !== 'dataLayer' ? '&l='+l : '';
  var scr = 'https://www.googletagmanager.com/gtm.js?id='+'GTM-NMVD2Z7'+dl;
  /*
      To avoid Multiple installations of google tag manager detected warning
  */
  if(!scriptExists(scr)){
    var f = d.getElementsByTagName(s)[0],
      j: HTMLScriptElement = d.createElement("script")
    j.async = true;
    j.src = scr;
    f?.parentNode?.insertBefore(j,f);
  }
})
const scriptExists = (url: string) => {
  var scripts = document.getElementsByTagName('script');
  for (let i = scripts.length; i--;) {
    if (scripts[i].src === url) return true;
  }
  return false;
}