import create from 'zustand'
import {persist} from "zustand/middleware"

export const useBasket = create(persist(
    (set, get) => ({
        basket: [],
        addToBasket: (item) => AddOrIncreaseItemInBasket(item, get, set),
        decOrRemoveItem: (item) => RemoveOrDecreaseFromBasket(item, get, set),
        removeItem: (item) => RemoveItem(item, get, set),
        emptyBasket: () => set({basket: []})
    }),
    {
        name: "droove_basket", // unique name
        getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
))

export const getBasketTotal = (warenkorb) => {
    const amount = warenkorb?.reduce((amount, item) => Number.parseFloat(item.price) * Number.parseFloat(item.count) + Number.parseFloat(amount), 0)
    const roundedNumber = Number.parseFloat(amount).toFixed(2)
    return Number.parseFloat(roundedNumber)
}


const AddOrIncreaseItemInBasket = (addedItem, get, set) => {
    let currentBasket = get().basket
    const foundItemIndex = currentBasket.findIndex((item) => item.id === addedItem.id)
    let result = []
    if (foundItemIndex > -1) {
        currentBasket[foundItemIndex]["count"] = currentBasket[foundItemIndex]["count"] + 1
        result = currentBasket
    } else {
        result = [...currentBasket, addedItem]
    }

    set({basket: result})
}

const RemoveOrDecreaseFromBasket = (item, get, set) => {
    let currentBasket = get().basket;
    const index = currentBasket.findIndex((basketItem) => basketItem.id === item.id);
    if (index >= 0) {
        // item exist in the basket, remove it
        if (currentBasket[index]["count"] <= 1) {
            currentBasket.splice(index, 1)
        } else {
            currentBasket[index]["count"] = currentBasket[index]["count"] - 1
        }
    } else {
        console.warn(
            `Can't remove product{id: ${item.id}} as it is not in the basket`
        );
    }

    set({basket: currentBasket})
}

const RemoveItem = (item, get, set) => {
    let currentBasket = get().basket;
    const indexOfItemNeedToDelete = currentBasket.findIndex((basketItem) => basketItem.id === item.id);
    currentBasket.splice(indexOfItemNeedToDelete, 1)
    set({basket: currentBasket})
}
