import {useDiscount} from "../GlobalStorage/discount";
import Modal from "./Modal";
import DiscountSVG from "../assets/discount.svg";
import {useContext, useState} from "react";
import {AuthContext} from "../Firebase/firebaseAuthProvider";
import {checkDiscount} from "../Firebase/paymentFunction";
import {useBasket, getBasketTotal} from "../GlobalStorage/warenkorbState";

const discountSelector = state => [state.discountInformation, state.addDiscountInfo]
const selector = state => [state.basket, state.removeItem]


const DiscountModal = () => {
    const [basket, removeItem] = useBasket(selector)
    const [discountInformation, addDiscountInfo] = useDiscount(discountSelector)
    const [processingDiscount, setProcessingDiscount] = useState(false);
    const [showDiscountModal, setShowDiscountModal] = useState(false);
    const [discountCode, setDiscountCode] = useState("");
    const [email, setEmail] = useState("");
    const [view, setView] = useState();

    const processDiscountCode = async () => {

        setProcessingDiscount(true)
        // Apply discount code regardless if the user has used it already or not
        if (!isValidEmail(email)) {
            console.log("Email is invalid")
            setView(<RabatCodeFailed errorMessage={"Bitte geben Sie eine E-Mail an, um den Rabatt zu aktivieren"} onClose={() => resetEveryThing()}/>)
        } else {
            const {valid, value, type, name, errorMessage} = await checkDiscount({amount: getBasketTotal(basket), discount: discountCode, customerEmail: email})

            if (!valid) {
                setView(<RabatCodeFailed errorMessage={errorMessage} onClose={() => resetEveryThing()}/>)
            } else {
                addDiscountInfo({discount: value, type, name, discountCode})
                setView(<RabatCodeSucc onClose={() => resetEveryThing()}/>)
            }
        }
        setProcessingDiscount(false)

    }

    const showRightView = () => {

        return view

    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const resetEveryThing = () => {
        setView()
        setShowDiscountModal(false)
        setProcessingDiscount(false)
        setDiscountCode("")
        setEmail("")
    }

    const getBasketTotal = (warenkorb) => {
        const amount = warenkorb?.reduce((amount, item) => Number.parseFloat(item.price) * Number.parseFloat(item.count) + Number.parseFloat(amount), 0)
        const roundedNumber = Number.parseFloat(amount).toFixed(2)
        return Number.parseFloat(roundedNumber)
    }

    return (
        <div>
            {
                !discountInformation.discount &&
                <div className="flex justify-center">
                    <button onClick={() => setShowDiscountModal(true)}
                            className="text-gray-500 flex justify-center items-center focus:outline-none"
                            href="/adress">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                        </svg>
                        Haben Sie einen Gutschein?
                    </button>
                </div>
            }

            <Modal show={showDiscountModal} closeClicked={() => resetEveryThing()}>

                {!view ?
                    <div className="inset-0">
                        <div className="flex flex-col justify-center items-center mx-auto p-5">

                            <div>
                                <img className="w-16 h-16" src={DiscountSVG}/>
                            </div>
                            <br/>
                            <h1 className="myTitle text-center">Gutschein</h1>
                            <br/>
                            {
                                processingDiscount ?
                                    <div className="loader">
                                    </div>
                                    :
                                    <div>
                                        <p className="myParagraph text-center">Sie können nur einen Gutschein pro
                                            Bestellung
                                            einsetzen</p>
                                        <br/>
                                        <input value={discountCode} onChange={(e) => setDiscountCode(e.target.value.toUpperCase())}
                                               type="text" className="myInputField text-center" placeholder="Gutschein hinzufügen"/>
                                        <br/>
                                        <br/>
                                        <input value={email} onChange={(e) => setEmail(e.target.value)}
                                               type="email" className="myInputField text-center" placeholder="Ihre Email*"/>
                                        <br/>
                                        <br/>
                                        <button onClick={() => processDiscountCode()} type="button"
                                                className="myButton ">
                                            Einlösen
                                        </button>
                                    </div>
                            }

                        </div>
                    </div>
                    :
                    showRightView()
                }


            </Modal>
        </div>)

}

const RabatCodeFailed = ({onClose, errorMessage}) => {

    return (
        <div className="inset-0">
            <div className="flex flex-col justify-center items-center mx-auto p-5">

                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-gray-300" viewBox="0 0 20 20"
                         fill="currentColor">
                        <path fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                              clipRule="evenodd"/>
                    </svg>
                </div>
                <br/>

                <h1 className="myTitle text-center">Sorry :(</h1>
                <br/>

                <div>
                    <p className="myParagraph text-center">{errorMessage}</p>
                    <br/>
                    <br/>
                    <button onClick={() => onClose()} type="button"
                            className="myButtonSecondary ">
                        Zurück
                    </button>
                </div>


            </div>
        </div>
    )
}


const RabatCodeSucc = ({onClose}) => {

    return (
        <div className="inset-0">
            <div className="flex flex-col justify-center items-center mx-auto p-5">

                <div>
                    <svg style={{color: "var(--main-green)"}} xmlns="http://www.w3.org/2000/svg" className="h-20 w-20"
                         viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"/>
                    </svg>
                </div>
                <br/>

                <h1 className="myTitle text-center">Erfolg!</h1>
                <br/>

                <div>
                    <p className="myParagraph text-center">Der Rabatt wird zu Ihrer Bestellung hinzugefügt</p>
                    <br/>
                    <br/>
                    <button onClick={() => onClose()} type="button"
                            className="myButtonSecondary ">
                        Weiter
                    </button>
                </div>


            </div>
        </div>
    )
}


export default DiscountModal
