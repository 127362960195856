import axios from "axios";

const liveURL = 'https://us-central1-droov-fbaef.cloudfunctions.net/payment'
const testURL = 'https://us-central1-droov-fbaef.cloudfunctions.net/testStripePayment';

const liveFunction = '/payment-intents'
const testFunction = '/payment-intents'

const wakeUp = "/wakeup"
const createPaypalPayURL = "/create_paypal_payment"
const completePayPalOrder = "/complete_paypal_order"
const testCreatePaypalPayURL = "/test_create_paypal_payment"
const testCompletePayPalOrder = "/test_complete_paypal_order"
const checkDiscountURL = "/checkdiscount"
//const localURL = "http://localhost:5001/droov-fbaef/us-central1/payment"
const instance = axios.create({
    baseURL: liveURL,
});

export async function serverWakeUp() {
    try {
        const response = await
            instance.get(wakeUp)
    } catch (e) {
        console.log("An error occurred", e.response?.data?.message)
    }
}

export async function getClientSecret(basketItems, deliveryInformation, contactInformation, user, customer, notes, paymentType, discountCode) {
    try {
        const response = await
            instance.post(liveFunction, {
                items: basketItems,
                deliveryInformation,
                contactInformation,
                user,
                customer,
                notes,
                paymentType,
                currency: "EUR",
                discountCode,
            },)

        /*const orderID = response.data.orderID
        const clientSecret = response.data.clientSecret*/
        console.log("getClientSecret response: " + response)
        return response

    } catch (error) {
        console.log("An error occurred in GetClientSecret", error)
        return ""
    }

}

export async function setPaymentAsSucc(paymentID) {
  try {
    const response = await
      instance.post("/createOrder", {
        intentRef: paymentID,
      })
  } catch (e) {
    console.log("An error occurred", e.response?.data?.message)
    return ""
  }
}

export async function createPaypalPayment(basketItems, deliveryInformation, contactInformation, user, customer, notes, paymentType, discountCode) {
    try {
        console.log("THIS IS CUSTOMER OBJECT: " + customer.email)
        const response = await
            instance.post(createPaypalPayURL, {
                items: basketItems,
                deliveryInformation,
                contactInformation,
                user,
                customer,
                notes,
                paymentType,
                currency: "EUR",
                discountCode,
            },)

        const orderID = response.data.orderID
        return orderID

    } catch (e) {
        console.log("An error occurred", e)
        return ""
    }

}

export async function completePaypalPayment(orderID) {

    try {
        const response = await
            instance.post(completePayPalOrder, {orderID},)

        const SomeResponse = response.data
        return SomeResponse

    } catch (e) {
        console.log("An error occurred", e.response?.data?.message)
        return ""
    }

}

export async function checkDiscount({discount, amount, customerEmail}) {

    try {
        const response = await
            instance.post(checkDiscountURL, {discount, amount, customerEmail})

        const SomeResponse = response.data.discountObject
        return SomeResponse

    } catch (e) {
        console.log("An error occurred", e.response?.data?.message)
        return {errorMessage: e.response?.data?.message}
    }

}
