import db from "./firebaseInit"
import {useEffect, useState} from "react";
import {calculateNextTwoWeeks} from "../Utils/useTimeManager"

export function useTourInformation(zipCode, startDay) {
    const [nextTwoWeeks, setNextTwoWeeks] = useState([]);
    useEffect(() => {
        if (zipCode) {
            applyFetch(zipCode, startDay)
        }

    }, [zipCode, startDay]);

    async function applyFetch(zipCode, startDay) {

        const ToursForRegions = await fetchToursForRegions(zipCode)
        const enabledDays = calculateEnabledDays(ToursForRegions)
        const nextTwoWeeks = calculateNextTwoWeeks(enabledDays, startDay?.date, startDay && 2)
        setNextTwoWeeks(nextTwoWeeks)
    }

    return [nextTwoWeeks]

}


const tourMap = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]

function calculateEnabledDays(tourInformation) {
    let availableDays = {}
    tourInformation.forEach((tour) => {
        const availableTours = tour?.tour?.days

        tourMap.forEach((day, index) => {
                if (availableTours[day]) {
                    if (availableDays[index+1]) {
                        availableDays[index+1] = [...availableDays[index+1], {
                            frame: tour.regions.timeFrame,
                            tourRef: tour?.regions?.tourRef,
                            regionID: tour?.regions?.id
                        }]
                    } else {
                        availableDays[index+1] = [{
                            frame: tour.regions.timeFrame,
                            tourRef: tour?.regions?.tourRef,
                            regionID: tour?.regions?.id
                        }]
                    }
                }
            }
        )
    })

    return availableDays
}


async function fetchToursForRegions(zipCode) {
    let ToursForRegions = []

    try {
        const Regions = await fetchRegions(zipCode)
        for (let i = 0; i < Regions.length; i++) {
            const TourInformation = await fetchTours(Regions[i]["tourRef"])

            ToursForRegions.push({regions: Regions[i], tour: TourInformation})
        }
    } catch (e) {
        console.log("Cannot Fetch Regsions with err:", e)
    }
    return ToursForRegions
}


async function fetchRegions(zipCode) {
    let Regions = []
    try {
        const querySnapshot = await db.collection("regions").where("zipCodes", "array-contains", zipCode).get()
        querySnapshot.forEach((doc) => {
            Regions.push({id: doc.id, ...doc.data()})
        })
    } catch (e) {
        console.log("Cannot fetch regions ", e)
    }

    return Regions


}

export async function fetchTours(id) {
    try {
        const dataSnapshot = await db.collection("tours-m").doc(id).get()

        if (dataSnapshot.exists)
            return {id: dataSnapshot.id, ...dataSnapshot.data()}
    } catch (e) {
        console.log("Cannot fetch Tours", e)
        return {}
    }

}
