import Basket from "../Components/Basket";
import ExtendedDeliveryInformation from "../Components/ExtendedDeliveryInformation";
import PaymentComponent from "../Components/PaymentComponent";
import {useBasket, getBasketTotal} from "../GlobalStorage/warenkorbState";
import {useNotes} from "../GlobalStorage/notes";
import {useEffect} from "react";
import {serverWakeUp} from "../Firebase/paymentFunction";
import {analytics} from "../analytics";

const selector = state => [state.basket]
const selectoNotes = state => [state.notes, state.setNotes]
export default function ExtendedSideBar() {
    const [basket] = useBasket(selector)
    const [notes, setNotes] = useNotes(selectoNotes)
    useEffect(() => {
        serverWakeUp()
        //TODO: restore tags
        // Google Tag Manager (For Kingsgard tracking of customer steps)
       /* window.dataLayer.push({'event': 'confirm'});*/
        analytics(window, document, 'script', 'dataLayer', 'confirm', 0);
    }, []);



    return (
        <div
            className="slideTotheLeft overflow-auto bg-white neon__shadow w-full lg:fixed lg:w-5/6 h-full right-0 top-0 bottom-0  lg:block px-4 lg:px-10"
            style={{zIndex: "99"}}>
            <br/>
            <br/>
            <div className="xl:grid grid-cols-3 w-full">
                <div className="xl:border-r  mb-5 lg:bg-white bg-gray-100 p-4 rounded-2xl lg:rounded-none">
                    <Basket deliveryDisabled={true}/>
                    <div className="w-full md:-mt-10">
                        <h1 className="text-2xl text-gray-800">Notizen</h1>
                        <br/>
                        <textarea value={notes} onChange={(e) => setNotes(e.target.value)}
                                  placeholder="Schreiben Sie hier Notizen für die Reinigung"
                                  className="w-full lg:bg-blue-50  p-2"
                                  name="" id="" cols="30" rows="5"/>
                    </div>
                </div>

                <div
                    className="xl:border-r xl:px-12 mb-5 mb-5  lg:bg-white bg-gray-100 p-4 rounded-2xl lg:rounded-none">
                    <ExtendedDeliveryInformation/>
                </div>

                <div className="xl:px-12 mb-5 mb-5 bg-gray-100 lg:bg-white p-4 rounded-2xl lg:rounded-none">
                    {basket?.length > 0 && getBasketTotal(basket) >= 30 ?
                        <PaymentComponent/>
                        :
                        <h1 className="text-red-700 ">Mindestbestellwert nicht erreicht!</h1>
                    }
                    <br/>
                    <br/>
                </div>
                <br/>
            </div>
        </div>
    )
}