export function calculateNextTwoWeeks(enabledDays, startDay, processingTime) {
    const nextTwoWeaks = []
    const now = new Date()
    const todaySix = new Date()
    todaySix.setHours(6, 0, 0)
    const adder = startDay ? processingTime : (now > todaySix ? 1 : 0)
    for (let i = 0; i < 14; i++) {
        const nextDay = startDay ? new Date(startDay) : new Date()
        nextDay.setDate(nextDay.getDate() + i + adder)
        const enabledDaysSorted = enabledDays[nextDay.getDay() + 1]?.sort(function(a, b) {
            return parseInt(a.frame.substr(0,2)) - parseInt(b.frame.substr(0,2))
        })

        nextTwoWeaks.push({date: nextDay, timeFrame: enabledDaysSorted || null})
    }

    return nextTwoWeaks

}
