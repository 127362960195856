import {useState} from "react";
import { ReactComponent as CardIcon } from '../assets/icon-card.svg';
import { ReactComponent as PayPalIcon } from '../assets/icon-paypal.svg';
import DiscountModal from "./DiscountModal"
import { PayPalPayment } from './StripeComponent';
import StripePaymentComponent from "./StripePaymentComponent";

export default function PaymentComponent() {

  //const [activePaymentTab, setActivePaymentTab] = useState(0);
  const [contactDetails, setContactDetails] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [formatMessage, setFormatMessage] = useState('');


  /*const paymentMethodes = [
    {"name": "PayPal", "icon": paypalIcon},
    {"name": "Card", "icon": card, id: 1},
    {
      "name": "Mobile",
      "icon": mobilePay,
      id: 2
    },
  ]*/

  const renderCardPayment = (contactDetails) => (
    <div id='payment' className='mt-4 m-auto'>
        <StripePaymentComponent
          onAbort={onCardPaymentAbort}
          customer={contactDetails}
        />
    </div>
  );

  const onCardPaymentAbort = () => {
    setPaymentMethod(undefined);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (event.target.name.value === '') {
      setFormatMessage('Bitte achten Sie darauf, den Namen auszufüllen');
      return;
    } else if (event.target.email.value === '') {
      setFormatMessage('Bitte geben Sie unbedingt Ihre Email an');
      return;
    } else if (event.target.paymentMethod.value === '') {
      setFormatMessage('Bitte wählen Sie eine Zahlungsmethode');
      return;
    } else {
      setFormatMessage(null);
    }

    setContactDetails({
      name: event.target.name.value,
      email: event.target.email.value,
    });

    setPaymentMethod(event.target.paymentMethod.value);
  };

  const renderPaymentOptions = () => (
    <div className='flex flex-row justify-center space-between gap-10 h-24'>

      <div>
        <input
          type='radio'
          name='paymentMethod'
          id='payPalPayment'
          value='PayPal'
          className='radio-button-input w-0 h-0 opacity-0'
        />
        <label
          htmlFor='payPalPayment'
          className='relative radio-button-label inline-block h-24 w-20 border-2 border-transparent transform transition duration-600 shadow-lg hover:shadow-sm rounded-2xl cursor-pointer flex flex-col justify-center items-center gap-2'
          style={{top: '-23px'}}
        >
          <PayPalIcon/>
          <span className='text-xs font-semibold text-gray-700'>PayPal</span>
        </label>
      </div>
      {/*)}*/}
      <div>
        <input
          type='radio'
          name='paymentMethod'
          id='cardPayment'
          value='Card'
          className='radio-button-input w-0 h-0 opacity-0'
        />
        <label
          htmlFor='cardPayment'
          className='relative radio-button-label inline-block h-24 w-20 border-2 border-transparent transform transition duration-600 shadow-lg hover:shadow-sm rounded-2xl cursor-pointer flex flex-col justify-center items-center gap-2'
          style={{top: '-23px'}}
        >
          <CardIcon/>
          <span className='text-xs font-semibold text-gray-700'>Card</span>
        </label>
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex justify-between">
        <h1 className="text-2xl text-gray-800">Kontakt und Zahlung</h1>
      </div>
      <h1 className='mt-6 text-sm text-gray-800 font-normal sm:whitespace-nowrap'>
        Sie werden per Email benachrichtigt
      </h1>

      <br/>

      <form
        className='flex flex-col items-center space-y-4'
        onSubmit={onSubmit}
      >
        <input
          id='name'
          name='name'
          type='text'
          placeholder='Ihre Name*'
          disabled={contactDetails && paymentMethod}
          className={`h-10 ring-1 w-full sm:w-100 rounded-xl py-2 outline-none mt-2 text-center items-center m-auto disabled:text-gray-500 disabled:border-gray-500`}
        />
        <input
          id='email'
          name='email'
          type='email'
          placeholder='Ihre Email*'
          disabled={contactDetails && paymentMethod}
          className={`h-10 ring-1 w-full sm:w-100 rounded-xl py-2 outline-none mt-2 text-center bg-droov-payment-email-bg-color items-center m-auto disabled:text-gray-500 disabled:border-gray-500 disabled:bg-white`}
        />

        <br/>
        {(contactDetails == null || paymentMethod == null) && (
          <div className='w-full sm:w-100 flex flex-col gap-4'>
            {renderPaymentOptions()}
            {formatMessage && (
              <label className='text-red-500 w-full lg:w-100 outline-none mt-2 text-center items-center m-auto justify-center'>
                {formatMessage}
              </label>
            )}
            <button
              className='myButton my-6'
              type='submit'
            >
              Bezahlen
            </button>
          </div>
        )}
      </form>

      {contactDetails != null &&
        paymentMethod === 'Card' &&
        renderCardPayment(contactDetails)}

      {contactDetails != null &&
        paymentMethod === 'PayPal' && (
        <div className='mt-10 flex flex-col items-center w-full'>
          <PayPalPayment customer={contactDetails}/>
          <button
            type='abort'
            className='my-6 text-sm font-medium text-gray-500'
            onClick={() => {
              setPaymentMethod(undefined);
            }}
          >
            Zurück
          </button>
        </div>
      )}
{/*

      <div className="flex justify-center">
        {
          paymentMethodes.map((object, index) =>
            <PaymentChip active={activePaymentTab === index}
                         icon={object.icon}
                         onClicked={() => setActivePaymentTab(index)}
                         key={index} name={object.name}/>)
        }

      </div>
      <br/>
      <br/>


*/}

      <br/>
      <DiscountModal/>

    </div>
  )
}
