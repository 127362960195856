import {useForm} from "react-hook-form";
import {useEffect, useState} from "react"
import {useHistory} from "react-router-dom"
import {useContact} from "../GlobalStorage/contactInformationState"
import {delivery} from "../routesConfig";
// import
import {OpenStreetMapProvider} from 'leaflet-geosearch';
import {analytics} from "../analytics";

// setup
const provider = new OpenStreetMapProvider({
    params: {
        'accept-language': 'de', // render results in English
        countrycodes: 'de',
    },
});


const selector = state => [state.contactInformation, state.addInformation]

function AdressPage() {
    const [contactInformation, addInformation] = useContact(selector)
    const history = useHistory()
    const {register, handleSubmit, errors, setValue} = useForm();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [invoiceAddressDiffers, setInvoiceAddressDiffers] = useState(false);

    const onSubmit = async (data) => {
        setLoading(true)

        const result = await fetch("https://us-central1-droov-fbaef.cloudfunctions.net/payment/checkzipcode?zipcode=" + data.zipcode)
        if (result.status !== 200) {
            setError("Es tut uns Leid. Wir liefern aktuell noch nicht in Ihre Umgebung.")
            setLoading(false)
            return
        }

        const results = await provider.search({query: `${data.street} ${data.housenumber} ${data.zipcode} ${data.city}`});

        if (results.length === 0) {
            setError("Adresse konte nicht gefunden werden!")
            setLoading(false)
            return
        }
        addInformation({...data, differentInvoiceAddress: invoiceAddressDiffers, coordinate: [results[0].x, results[0].y]})
        setLoading(false)
        history.push(delivery)
    }

    // eslint-disable-next-line
    useEffect(() => {
        if (!contactInformation) {
            return
        }
        if (contactInformation["differentInvoiceAddress"]){
            console.log("Came here")
            setInvoiceAddressDiffers(true)
        }
        for (const [key, value] of Object.entries(contactInformation)) {
            setValue(key, value)
        }

        // eslint-disable-next-line
    }, [contactInformation]);

    useEffect(() => {
        // Google Tag Manager (For Kingsgard tracking of customer steps)
       /* window.dataLayer.push({'event': 'adresse'});*/
        analytics(window, document, 'script', 'dataLayer', 'adresse', 0);
    }, []);


    return (
        <div className="max-w-lg mx-auto">

            <div>
                <h1 className="myTitle">Ihre Lieferadresse</h1>
                <br/>
                <br/>
                {/*Here is the toggle*/}

                <form onSubmit={handleSubmit(onSubmit)} id="adressForm" onChange={() => setError("")}>

                    <div className="inline-flex items-center mb-6">
                        <input type="checkbox" name="differentInvoiceAddress" className="form-checkbox h-4 w-4 text-indigo-600" checked={invoiceAddressDiffers} onChange={() => setInvoiceAddressDiffers(!invoiceAddressDiffers)}/>
                        <span className="ml-2 text-gray-700">Rechnungsadresse abweichend</span>
                    </div>

                    <input type="name" name="name"
                           className={`myInputField ${errors.name ? "border border-red-500" : ""}`}
                           ref={register({required: true})}
                           placeholder="Name *" autoComplete="off"/>
                    <br/>
                    <br/>
                    <div className="grid grid-cols-3 gap-6">
                        <input type="name" name="street"
                               ref={register({required: true})}
                               className={`col-span-2 myInputField ${errors.street ? "border border-red-500" : ""}`}
                               placeholder="Straße *" autoComplete="off"/>
                        <input type="name" name="housenumber"
                               ref={register({required: true})}
                               className={`col-span-1 myInputField ${errors.housenumber ? "border border-red-500" : ""}`}
                               placeholder="Hausnummer *" autoComplete="off"/>
                    </div>
                    <br/>
                    <div className="grid grid-cols-3 gap-6">
                        <input type="name" name="zipcode"
                               ref={register({required: true})}
                               className={`col-span-1 myInputField ${errors.zipcode ? "border border-red-500" : ""}`}
                               placeholder="Postleitzahl *" autoComplete="off"/>
                        <input type="name" name="city"
                               ref={register({required: true})}
                               className={`col-span-2 myInputField ${errors.city ? "border border-red-500" : ""}`}
                               placeholder="Stadt *" autoComplete="off"/>
                    </div>
                    <br/>
                    <input type="tel" name="telephone"
                           ref={register({required: true})}
                           className={`myInputField ${errors.telephone ? "border border-red-500" : ""}`}
                           placeholder="Telefonnummer *" autoComplete="off"/>
                    <br/>
                    <br/>

                    <textarea type="name" name="notes"
                              ref={register()}
                              className="myInputField h-32 text-gray-600"
                              placeholder="Lieferanweisungen für einen Kurier (z. B. zweiter Stock...)"
                              autoComplete="off"/>
                    <br/>
                   <div hidden={!invoiceAddressDiffers}>

                        <h1 className="myTitle mt-6 mb-4">Ihre Rechnungsadresse</h1>

                       <input type="name" name="invoiceCompanyName"
                              className={`myInputField ${errors.invoiceCompanyName ? "border border-red-500" : ""}`}
                              ref={register({required: invoiceAddressDiffers})}
                              placeholder="Firmenname *" autoComplete="off"/>
                       <br/>
                       <br/>
                        <input type="name" name="invoiceName"
                               className={`myInputField ${errors.invoiceName ? "border border-red-500" : ""}`}
                               ref={register({required: false})}
                               placeholder="Name" autoComplete="off"/>
                        <br/>
                        <br/>
                        <div className="grid grid-cols-3 gap-6">
                            <input type="name" name="invoiceStreet"
                                   ref={register({required: invoiceAddressDiffers})}
                                   className={`col-span-2 myInputField ${errors.invoiceStreet ? "border border-red-500" : ""}`}
                                   placeholder="Straße *" autoComplete="off"/>
                            <input type="name" name="invoiceHousenumber"
                                   ref={register({required: invoiceAddressDiffers})}
                                   className={`col-span-1 myInputField ${errors.invoiceHousenumber ? "border border-red-500" : ""}`}
                                   placeholder="Hausnummer *" autoComplete="off"/>
                        </div>
                        <br/>
                        <div className="grid grid-cols-3 gap-6">
                            <input type="name" name="invoiceZipcode"
                                   ref={register({required: invoiceAddressDiffers})}
                                   className={`col-span-1 myInputField ${errors.invoiceZipcode ? "border border-red-500" : ""}`}
                                   placeholder="Postleitzahl *" autoComplete="off"/>
                            <input type="name" name="invoiceCity"
                                   ref={register({required: invoiceAddressDiffers})}
                                   className={`col-span-2 myInputField ${errors.invoiceCity ? "border border-red-500" : ""}`}
                                   placeholder="Stadt *" autoComplete="off"/>
                        </div>
                        <br/>
                        <input type="name" name="invoiceCountry"
                               ref={register({required: invoiceAddressDiffers})}
                               className={`col-span-2 myInputField ${errors.invoiceCountry ? "border border-red-500" : ""}`}
                               placeholder="Land *" autoComplete="off"/>
                    </div>
                </form>
            </div>


            <br/>
            {error && <p className="text-red-700">{error}</p>}
            <br/>

            {loading ? <div className="loader"></div> :
                <button type="submit" form="adressForm" className="myButton bottom-0">Weiter</button>}

        </div>
    );
}

export default AdressPage;
