import create from 'zustand'
import {persist} from "zustand/middleware"

export const useContact = create(persist(
    (set, get) => ({
        contactInformation: [],
        addInformation: (info) => set({contactInformation: info}),
    }),
    {
        name: "droove_contact_information", // unique name
        getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
))
