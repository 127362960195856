import { useCallback } from 'react';

const useGtmEvent = () => {
  const pushToDataLayer = useCallback((eventName, additionalData = {}) => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: eventName,
        ...additionalData,
      });
    }
  }, []);

  return {
    pushToDataLayer,
  };
};

export default useGtmEvent;