import React, {useEffect, useState, createContext} from "react";
import db, {auth} from "./firebaseInit";


export const handleLogout = () => {
    auth
        .signOut()
        .then(res => {
            console.log("logged out")
        })
}

export const getDeliveryFee = async (regionID) => {
    console.log(regionID)
    if (!regionID) {
        return 0
    }
    try {
        const regionRef = await db.collection("regions").doc(regionID).get()
        if (regionRef.exists) {
            return regionRef.data().deliveryFee
        } else {
            return 0
        }
    } catch (e) {
        console.log("Cannot register user with err: ", e.message)
        return 0
    }
}
export const RegisterUser = async (email, password, vorname, nachname) => {
    try {
        const userRef = await auth.createUserWithEmailAndPassword(email, password)
        await db.collection("customers").doc(userRef?.user?.uid).set(
            {
                createdAt: new Date(),
                email,
                firstName: vorname,
                lastName: nachname,
                uid: userRef?.user?.uid
            }
        )
    } catch (e) {
        console.log("Cannot register user with err: ", e)
        return e.message
    }
}


export const LoginUser = async (email, password) => {
    try {
        await auth.signInWithEmailAndPassword(email, password)
    } catch (e) {
        console.log("Cannot login user with err: ", e)
        return e.message
    }
}


export const AuthContext = createContext({})
export const AuthProvider = ({children}) => {
    const [user, setUser] = useState();
    const [loadingAuthState, setLoadingAuthState] = useState(true);

    const [userInformation, setuserInformation] = useState()

    // eslint-disable-next-line
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUser(user);
            setLoadingAuthState(true)

            if (user) {
                db.collection("customers").doc(user?.uid).get().then(document => {
                    document.exists && setuserInformation(document.data())
                    setLoadingAuthState(false);

                }).catch(error => {
                    setLoadingAuthState(false);
                })
            } else {
                setLoadingAuthState(false);
            }
        });
        // eslint-disable-next-line
    }, [db, auth]);
    return (
        <AuthContext.Provider
            value={{
                user,
                authenticated: user !== null,
                setUser,
                userInformation: userInformation,
                haveInformation: userInformation !== null,
                loadingAuthState,
            }}>
            {children}
        </AuthContext.Provider>
    );
}
