import styled from "styled-components"
import {Plus, Minus} from "react-feather";
import {useState} from "react"
import {useBasket} from "../GlobalStorage/warenkorbState"

const ProductStyle = styled.div`
  width: 100%;
  height: 160px;
  border: 1px solid lightgray;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
`


function Counter({onIncreased, onDecreased, count}) {


    function Increase() {
        onIncreased()
    }

    function Decrease() {
        onDecreased()
    }


    return (
        <div>

            <div className="flex justify-center items-center m-1">
                <button
                    className="bg-gray-900 hover:shadow-lg mr-2 text-white rounded-xl focus:outline-none p-2 transition duration-300 ease-in-out hover:bg-gray-600 md:bg-gray-800"
                    onClick={() => Decrease()}>
                    <Minus/>
                </button>
                <p className="mr-2">{count || 0}</p>
                <button
                    className="bg-gray-900 hover:shadow-lg text-white rounded-xl focus:outline-none p-2 transition duration-300 ease-in-out hover:bg-gray-600 md:bg-gray-800 "
                    onClick={() => Increase()}>
                    <Plus/>
                </button>
            </div>
        </div>
    )
}

const selector = state => [state.basket, state.addToBasket, state.decOrRemoveItem]

function Product({title, imageURL, price, description, id, catID}) {
    const [basket, addToBasket, decOrRemoveItem] = useBasket(selector)
    const [fallBackImage, setFallBackImage] = useState("");

    const noImageURL = "https://firebasestorage.googleapis.com/v0/b/droov-fbaef.appspot.com/o/static_images%2FScreenshot%202021-02-12%20at%2020.20.00.png?alt=media&token=5eed2acd-fc62-4114-90e8-99637a89e2b1"
    let count = basket.find((item) => item.id === id)?.count
    const onaAddToBasket = () => {
        addToBasket({title, id, imageURL, price, count: 1, catID})
    }

    const removeFromBasket = () => {
        decOrRemoveItem({title, id, imageURL, price})
    }

    return (
        <ProductStyle>


            <img
                className="imageFadeIn  w-full bg-center bg-cover absolute md:relative md:w-1/3 md:h-full md:z-10"
                style={{objectFit: "cover"}}
                src={fallBackImage || imageURL}
                onError={() => setFallBackImage(noImageURL)}
                alt=""/>


            <div
                className="flex justify-between w-full pl-6 pt-2 pr-3 items-center z-10 text-gray-100 bg-gray-900 bg-opacity-50 md:bg-white md:z-0 md:bg-none md:text-gray-900">
                <div>
                    <p className="text-lg font-medium">{title}</p>
                    <br/>
                    <p className="text-sm font-light">{description}</p>

                </div>

                <div className="flex flex-col md:flex md:flex-row items-center">

                    <Counter count={count} onIncreased={() => onaAddToBasket()} onDecreased={() => removeFromBasket()}/>
                    <p className="md:order-first text-xl md:text-blue-400 mr-2 mt-2 whitespace-nowrap">€ {price?.split(".").join(",")}</p>

                </div>

            </div>


        </ProductStyle>
    );
}

export default Product;