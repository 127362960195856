import styled from 'styled-components'

const DateMap = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
const Months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
const TimeCarusell = ({dateArray, activeDate, onDaySelected}) => {

    return (
        <Layout>
            <CarusellLayoout>
                {dateArray?.map((element, index) =>
                    <TimeChip disabled={!dateArray[index].timeFrame}
                              onClicked={() => onDaySelected(index, dateArray[index])}
                              active={activeDate === index}
                              key={index}
                              onNotPossible={() => console.log("Hallo it is not possible")}
                              day={element?.date?.getDate()}
                              month={element?.date?.getMonth()}
                              dayName={DateMap[element?.date?.getDay()]}/>)}
            </CarusellLayoout>
        </Layout>

    );
};

export function TimeChip({day, dayName, active, onClicked, disabled, month, onNotPossible}) {

    active && disabled && onNotPossible()
    return (
        <Chip
            onClick={() => !disabled && onClicked()}
            className={`${!disabled ? "hover:bg-blue-50  cursor-pointer" : "opacity-40  cursor-not-allowed"} border mr-3 h-20 rounded-2xl overflow-x-hidden ${active ? " border-blue-500 bg-blue-50" : "border-gray-200"}`}>
            <div className="flex flex-col h-full items-center  w-full">
                <div className="w-full border-b text-white"><p
                    className="w-full text-xs text-white bg-gray-800 break-normal  break-all"
                    style={{fontSize: "0.5rem"}}>{Months[month]}</p></div>
                <h1 className="text-2xl font-semibold mt-1">{day}</h1>
                <p className="text-gray-600 break-normal  break-all" style={{fontSize: "0.5rem"}}>{dayName}</p>
            </div>
        </Chip>
    )
}

export default TimeCarusell


export const CarusellLayoout = styled.div`
  position: relative;
  margin-bottom: 20px;
  overflow-x: scroll;
  white-space: nowrap;
  box-sizing: border-box;
  top: 0;
  background: var(--bg-color);
  z-index: 30;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`

export const Chip = styled.div`
  display: inline-block;
  width: 70px;
  align-content: center;
`


export const Layout = styled.div`
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-flex: 1;
  width: 100%;
  flex-shrink: 0;
`



