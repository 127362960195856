import {CardElement, PaymentRequestButtonElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {
  getClientSecret,
  setPaymentAsSucc,
  createPaypalPayment,
  completePaypalPayment
} from "../Firebase/paymentFunction";
import {useContact} from "../GlobalStorage/contactInformationState";
import {useBasket, getBasketTotal} from "../GlobalStorage/warenkorbState";
import {useHistory} from "react-router-dom"
import React, {useState, useEffect, useContext} from "react";
import {useDeliveryInformationStore} from "../GlobalStorage/deliveryInformationState";
import {AuthContext, getDeliveryFee} from "../Firebase/firebaseAuthProvider";
import {PayPalButton} from "react-paypal-button-v2";
import {useNotes} from "../GlobalStorage/notes";
import {useDiscount} from "../GlobalStorage/discount";
import {analytics} from "../analytics";

const selectorContact = state => [state.contactInformation, state.addInformation]
const selector = state => [state.basket, state.emptyBasket]
const selectorNotes = state => [state.notes, state.setNotes]
const discountSelector = state => [state.discountInformation, state.clearDiscountInfos]
const stripePromise = loadStripe('pk_live_51ISLv8CllkC8pWneRXoF7h09qVadMcRAv2QC9tlYiD4S4OxfBYwt4qJDrW8FeYpS2STZfg1iMWf8NR7FOF8rLaU300a1GSwhaF');
//const stripePromise = loadStripe('pk_test_51ISLv8CllkC8pWneek04xl3Z2qH3i1wBaz7snxpptSmdDGsikuAqzMcvZoWP1utbvqcOzLLZf9T8vBdC7sgNtrkI00G4TXrd5f');
const selectorDeliveryInformation = state => [
  state.pickupDate,
  state.dropOffDate,
  state.pickupTimeFrame,
  state.dropOffTimeFrame,
  state.pickupRef,
  state.dropOffref,
  state.pickupRegionID,
  state.dropOffRegionID,
]


export const CheckoutForm = ({activePaymentType}) => {

  return (
    <Elements stripe={stripePromise}>
      <div className={`${activePaymentType !== "Card" && "hidden"} `}>
        <CardPayment/>
      </div>
      <div className={`${activePaymentType !== "Mobile" && "hidden"} `}>
        <MobilePayment/>
      </div>
      <div className={`${activePaymentType !== "PayPal" && "hidden"} `}>
        <PayPalPayment/>
      </div>
      <div className={`${activePaymentType !== "Cash" && "hidden"} `}>
        <h1>Cash</h1>
      </div>
    </Elements>
  );
}


function CardPayment() {
  const history = useHistory()
  const stripe = useStripe();

  const elements = useElements();
  const [contactInformation] = useContact(selectorContact)
  const [basket, emptyBasket] = useBasket(selector)
  const [notes, setNotes] = useNotes(selectorNotes)
  const [processing, setProcessing] = useState(false);
  const [discountInformation, clearDiscountInfos] = useDiscount(discountSelector)
  const [pickupDate,
    dropOffDate,
    pickupTimeFrame,
    dropOffTimeFrame,
    pickupRef,
    dropOffref,
    pickupRegionID,
    dropOffRegionID,
  ] = useDeliveryInformationStore(selectorDeliveryInformation)

  const {userInformation, user} = useContext(AuthContext);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setProcessing(true)
    const cardElement = elements.getElement(CardElement);

    const {orderID, clientSecret} = await getClientSecret(basket, {
      pickupDate,
      dropOffDate,
      pickupTimeFrame,
      dropOffTimeFrame,
      pickupRef,
      dropOffref,
      pickupRegionID,
      dropOffRegionID,
    }, contactInformation, userInformation, notes, "Credit Card", discountInformation.discountCode)

    if (!clientSecret) {
      console.log("Cannot get the client Secret")
      return
    }


    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          // address: contactInformation?.street + ", " + contactInformation?.housenumber,
          email: user.email,
          // phone: contactInformation?.telephone,
          name: contactInformation?.name,
        },
      }
    });
    if (payload.error) {
      setProcessing(false)
      console.log(`Payment failed ${payload.error.message}`);
    } else {

      console.log("Successful payed the amount")
      clearDiscountInfos()
      await setPaymentAsSucc(orderID)
      setNotes("")

      emptyBasket()
      history.push("/success")
    }
  };

  return (
    <div>
      {
        processing && <div className="loadingOverlay">
          <div className="loader"></div>
        </div>
      }
      <form onSubmit={handleSubmit}>
        <br/>
        <div className="bg-blue-50 py-3 rounded-2xl border px-1">
          <CardElement/>
        </div>

        <br/>
        {
          processing ?
            <div className="loader">

            </div>
            :
            <button type="submit" disabled={!stripe}
                    className="myButton bottom-0">Bezahlen
            </button>
        }
        <br/>
      </form>

    </div>
  )
}


function MobilePayment() {
  const [basket, emptyBasket] = useBasket(selector)
  const [contactInformation] = useContact(selectorContact)
  const [notes, setNotes] = useNotes(selectorNotes)
  const [discountInformation, clearDiscountInfos] = useDiscount(discountSelector)
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const history = useHistory()
  const [processing, setProcessing] = useState(false);
  const [errorText, setErrorText] = useState();
  const [pickupDate,
    dropOffDate,
    pickupTimeFrame,
    dropOffTimeFrame,
    pickupRef,
    dropOffref,
    pickupRegionID,
    dropOffRegionID,
  ] = useDeliveryInformationStore(selectorDeliveryInformation)
  const {userInformation} = useContext(AuthContext);


  useEffect(() => {

    if (paymentRequest) {
      window.location.reload(false);
      return
    }

    if (stripe) {
      makePaymentRequest()
    }

    // eslint-disable-next-line
  }, [stripe, getBasketTotal(basket), discountInformation]);


  async function makePaymentRequest() {

    let deliveryFee = 0

    if (getBasketTotal(basket) < 100) {
      deliveryFee = await getDeliveryFee(pickupRegionID)
    }

    const getGesammtBetrag = () => getBasketTotal(basket) + Number.parseInt(deliveryFee)

    const getDiscountOf = (betrag) => {

      if (!discountInformation.discount) {
        return 0
      }

      const discount = Number.parseFloat(discountInformation.discount)

      if (discountInformation.type === "Percent") {
        return betrag * discount / 100
      } else {
        return discount
      }
    }

    const getGesammtBetragAfterDiscount = () => {
      const betragVorDiscount = getGesammtBetrag()
      if (discountInformation.discount) {
        return betragVorDiscount - getDiscountOf(betragVorDiscount)
      }
      return betragVorDiscount
    }

    const pr = stripe.paymentRequest({
      country: 'DE',
      currency: 'eur',
      total: {
        label: 'Reinio.de',
        amount: parseInt(Math.round((getGesammtBetragAfterDiscount() * 100)).toFixed(0))
      },
      requestPayerEmail: true,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then(result => {
      if (result) {
        setPaymentRequest(pr);
      } else {
        setProcessing(false)
        setErrorText("Mobile Bezahlung nicht möglich.")
      }

    })
  }


  paymentRequest && paymentRequest.on('paymentmethod', async (ev) => {
    // Confirm the PaymentIntent without handling potential next actions (yet).

    setProcessing(true)
    const {orderID, clientSecret} = await getClientSecret(basket, {
      pickupDate,
      dropOffDate,
      pickupTimeFrame,
      dropOffTimeFrame,
      pickupRef,
      dropOffref,
      pickupRegionID,
      dropOffRegionID,

    }, contactInformation, userInformation, notes, "MobilePay", discountInformation.discountCode)

    const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
      clientSecret,
      {payment_method: ev.paymentMethod.id},
      {handleActions: false}
    );

    if (confirmError) {
      ev.complete('fail');
      setProcessing(false)
    } else {
      ev.complete('success');
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const {error} = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
          setErrorText(error)
        } else {
          // The payment has succeeded.
          /*window.dataLayer.push({
            'event': 'thank_you',
            'conversionValue': getBasketTotal(basket),
          });*/
          analytics(window, document, 'script', 'dataLayer', 'purchase', getBasketTotal(basket));
          emptyBasket()
          setNotes("")
          clearDiscountInfos()
          setProcessing(false)
          history.push("/success")
        }
      } else {
        // The payment has succeeded.
       /* window.dataLayer.push({
          'event': 'thank_you',
          'conversionValue': getBasketTotal(basket),
        });*/
        analytics(window, document, 'script', 'dataLayer', 'purchase', getBasketTotal(basket));
        emptyBasket()
        setNotes("")
        clearDiscountInfos()
        await setPaymentAsSucc(orderID)
        history.push("/success")
      }
    }
  });

  if (errorText) {

    return (
      <div className="text-red-700">
        {errorText}
      </div>
    )
  }


  return (
    <div>
      {
        paymentRequest ? <PaymentRequestButtonElement options={{paymentRequest}}/> :
          <div className="loader"></div>
      }

      {
        processing && <div className="loadingOverlay">
          <div className="loader"></div>
        </div>
      }

    </div>
  )
}


export function PayPalPayment({customer}) {

  // const [customerContactDetails, setCustomerContactDetails] = useState(customer) // TODO: added customer info here... needed? Maybe email alone is enough
  const [basket, emptyBasket] = useBasket(selector)
  const [contactInformation] = useContact(selectorContact)
  const [notes, setNotes] = useNotes(selectorNotes)
  const [discountInformation, clearDiscountInfos] = useDiscount(discountSelector)
  const [loading, setLoading] = useState(false);
  const [pickupDate,
    dropOffDate,
    pickupTimeFrame,
    dropOffTimeFrame,
    pickupRef,
    dropOffref,
    pickupRegionID,
    dropOffRegionID,
  ] = useDeliveryInformationStore(selectorDeliveryInformation)

  const {userInformation} = useContext(AuthContext);
  const history = useHistory()
  return (
    <div>
      {
        loading && <div className="loadingOverlay">
          <div className="loader"></div>
        </div>
      }
      <div className={`${!loading && "hidden"} loader`}>
      </div>
      <div className={`${loading && "hidden"} `}>
        <PayPalButton
          style={{
            label: 'paypal',
            layout: 'horizontal',
            size: 'large', // small | medium | large | responsive
            shape: 'pill', // pill | rect
            color: 'gold',
            height: 40,
          }}
          cartCurrency="EUR"
          currency="EUR"
          options={{
            currency: "EUR",
            clientId: "AccmKrs5ka9O95F7pIYucofK6NWpJ1Uatj42Ev2apGnEtqjmfSCKMspuOo7hGIb1vIEsdqRnHXXDr7d-",
            disableFunding: ['sepa', 'giropay', 'sofort', 'card'],
          }}
          createOrder={async function (data, actions) {
            setLoading(true)
            return createPaypalPayment(basket, {
              pickupDate,
              dropOffDate,
              pickupTimeFrame,
              dropOffTimeFrame,
              pickupRef,
              dropOffref,
              pickupRegionID,
              dropOffRegionID,
            }, contactInformation, userInformation, customer, notes, "PayPal", discountInformation.discountCode).then(function (orderData) {
              return orderData;
            });
          }}
          onApprove={async function (data) {
            return completePaypalPayment(data.orderID).then(function (orderData) {
              if (orderData.data?.status === "COMPLETED") {
                /*window.dataLayer.push({
                    'event': 'thank_you',
                    'conversionValue': getBasketTotal(basket),
                });*/
                analytics(window, document, 'script', 'dataLayer', 'purchase', getBasketTotal(basket));
                emptyBasket()
                setNotes("")
                clearDiscountInfos()
                history.push("/success")
              } else {
                console.log("Something went wrong", orderData)
              }
            });
          }}
          onError={function () {
            console.log("Error occured")
            setLoading(false)
          }}
          onCancel={function () {
            console.log("Order has been cancelled")
            // TODO: cancel payment intent
            setLoading(false)
          }}
        />
      </div>

    </div>

  );
}


