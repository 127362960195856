import {useEffect} from "react";

const Modal = ({show, closeClicked, children}) => {


    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [show]);


    return (

        <div>{show &&
        <div className=" fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
             aria-modal="true">
            <div
                className="flex items-end justify-center items-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                <div onClick={() => closeClicked()}
                     className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                     aria-hidden="true"></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen"
                      aria-hidden="true">&#8203;</span>

                <div
                    className=" inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all slideToTop sm:my-8 sm:align-middle md:max-w-lg w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative">
                        <div className="absolute right-2 top-2">
                            <button onClick={() => closeClicked()} className="focus:outline-none">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="h-6 w-6 text-gray-400 hover:text-gray-700 transition-all duration-150 focus:outline-none"
                                     fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1}
                                          d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>

                        </div>
                        <div className="w-full">
                            {children}
                        </div>
                    </div>

                </div>
            </div>
        </div>
        }

        </div>


    );
};

export default Modal