import styled from "styled-components"

const LayoutStyle = styled.div`
  max-width: 800px;
  width: 100%;
  margin: auto;
  padding: 15px;
  box-sizing: border-box;
  
`


export default function Layout(props) {


    return (
        <LayoutStyle>
            {props.children}
        </LayoutStyle>
    );

}