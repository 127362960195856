import styled from 'styled-components'
import {useState} from "react"

const Carusell = ({categories, selectedCategory}) => {

    const [activeTab, setActiveTab] = useState(0);


    function SelectTab(index, order) {
        setActiveTab(index)
        selectedCategory(order)
    }

    return (
        <Layout>
            <CarusellLayoout>
                {categories.map((item, index) =>
                    <Chip
                        onClick={() => SelectTab(index, item.order)}
                        className={`hover:bg-blue-50 border border-gray-200 mr-2 h-20  rounded-2xl  overflow-x-hidden ${activeTab === index ? 'bg-blue-100  border border-blue-400' : ''}`}
                        key={index}>
                        <div className="flex flex-col h-full justify-center items-center w-full">
                            <img className="w-10 max-h-10 object-contain mb-2"
                                 src={item.imageURL}
                                 alt=""/>

                            <h1 className=" text-xs text-gray-600 break-normal   break-all">{item.catname}</h1>
                        </div>

                    </Chip>)}

                {/*<button className="sticky right-0 " style={{marginRight:"-20px"}}>*/}
                {/*    <ChevronRight style={{width: "80px", height: "80px"}}/>*/}
                {/*</button>*/}
            </CarusellLayoout>
        </Layout>

    );
};

export default Carusell


export const CarusellLayoout = styled.div`
  position: relative;
  margin-bottom: 20px;
  overflow-x: scroll;
  white-space: nowrap;
  box-sizing: border-box;
  top: 0;
  background: var(--bg-color);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
`

export const Chip = styled.div`
  display: inline-block;
  cursor: pointer;
  max-height: 100px;
  text-align: center;
  margin-right: 30px;
  align-content: center;
  -webkit-align-content: center !important;
  vertical-align: top !important;
  padding:10px;
  box-sizing: border-box;
`


export const Layout = styled.div`
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-flex: 1;
  width: 100%;
  flex-shrink: 0;
`

