import EditButton from "./EditButton";
import {useState, useEffect} from "react";
import DeleteButton from "./DeleteButton";
import {useBasket, getBasketTotal} from "../GlobalStorage/warenkorbState";
import {useContact} from "../GlobalStorage/contactInformationState";
import {useDeliveryInformationStore} from "../GlobalStorage/deliveryInformationState"
import {getFormattedDate} from "../Utils/DateFormatter"
import {getDeliveryFee} from "../Firebase/firebaseAuthProvider"
import {products} from "../routesConfig";
import {useDiscount} from "../GlobalStorage/discount";
import {useLocation} from "react-router-dom";
import {fetchTours} from "../Firebase/useTourInformation";

const selector = state => [state.basket, state.removeItem]
const selectorContact = state => [state.contactInformation, state.addInformation]
const discountSelector = state => [state.discountInformation, state.clearDiscountInfos]
const selectorDeliveryInformation = state => [
    state.pickupDate,
    state.dropOffDate,
    state.pickupTimeFrame,
    state.dropOffTimeFrame,
    state.pickupRegionID,
    state.pickupRef
]


const Basket = (props) => {
    const [basket, removeItem] = useBasket(selector)
    const [discountInformation, clearDiscountInfos] = useDiscount(discountSelector)
    const [contactInformation] = useContact(selectorContact)
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [feeDrop, setFeeDrop] = useState(null);

    const [pickupDate,
        dropOffDate,
        pickupTimeFrame,
        dropOffTimeFrame,
        pickupRegionID,
        pickupRef
    ] = useDeliveryInformationStore(selectorDeliveryInformation)


    const fetchTourInformation = async () => {
        if (!pickupRef) return
        const tour = await fetchTours(pickupRef)

        if (tour.hasFeeDrop) {
            setFeeDrop(tour.noFeeFrom)
        }
        if (getBasketTotal(basket) >= Number.parseFloat(tour.noFeeFrom)) {
            setDeliveryFee(0)
        } else {
            setFees()
        }
    }

    const deleteItem = (id) => {
        removeItem({id})
        clearDiscountInfos()
    }

    useEffect(() => {
        fetchTourInformation()
    }, [pickupRegionID, getBasketTotal(basket), pickupRef]);

    async function setFees() {
        const delFees = await getDeliveryFee(pickupRegionID)
        setDeliveryFee(delFees)
    }


    const getGesammtBetrag = () => getBasketTotal(basket) + Number.parseFloat(deliveryFee)

    const getDiscountOf = (betrag) => {

        if (!discountInformation.discount) {
            return 0
        }

        const discount = Number.parseFloat(discountInformation.discount)

        if (discountInformation.type === "Percent") {
            return betrag * discount / 100
        } else {
            return discount
        }
    }

    const getGesammtBetragAfterDiscount = () => {
        const betragVorDiscount = getGesammtBetrag()
        if (discountInformation.discount) {
            return betragVorDiscount - getDiscountOf(betragVorDiscount)
        }
        return betragVorDiscount
    }

    //console.log("Delivery Fee: ", deliveryFee)

    return (
        <div className="w-full mx-auto  top-5 flex flex-col justify-between md:pb-12 pb-1 "
             style={props.displayDelivery ? {minHeight: "100vh"} : {}}>
            <div>

                <div className="flex justify-between ">
                    <p className="text-gray-800 text-2xl ">Wäschekorb</p>
                    <a className="text-gray-700 flex justify-center items-center " href={products}>Editieren
                        <svg xmlns="http://www.w3.org/2000/svg"
                             className="w-5 ml-2"
                             fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                        </svg></a>
                </div>
                <br/>
                <div className="w-full overflow-y-scroll md:pr-5 box-content md:max-h-72"
                >
                    {
                        basket.map((item, index) => <BasketItem
                            onDeleted={() => deleteItem(item.id)}
                            count={item.count}
                            key={index} title={item.title}
                            price={item.price}
                            imageURL={item.imageURL}/>)
                    }
                    <div className="md:h-10"/>
                    <div
                        className="hidden lg:block w-full h-20 gradientFromTo -mt-20 sticky bottom-0 "
                        style={{pointerEvents: "none"}}/>
                </div>

                <br/>
                <div className="flex justify-between mb-2">
                    <p className="text-gray-500 text-lg">Zwischenstand</p>
                    <p className="text-gray-500 text-lg">{getBasketTotal(basket).toFixed(2).split(".").join(",")} €</p>
                </div>
                {deliveryFee > 0 &&
                <div className="flex justify-between mb-2">
                    <div>
                        <p className="text-gray-500 text-lg">Abhol- und Lieferkosten</p>
                        {feeDrop && <p className="text-gray-500 text-xs">(entfällt ab {feeDrop}€)</p>}
                    </div>
                    <p className="text-gray-500 text-lg">{Number.parseFloat(deliveryFee).toFixed(2).split(".").join(",")} €</p>
                </div>
                }

                {
                    discountInformation.discount > 0 &&
                    <div className="flex justify-between mb-2">
                        <p className="text-gray-500 text-lg">Rabatt
                            ({discountInformation.type === "Percent" ? (discountInformation.discount + "%") : "Gutschein"})</p>
                        <p className="text-gray-500 text-lg">-
                             {getDiscountOf(getGesammtBetrag()).toFixed(2).split(".").join(",")} €</p>
                    </div>
                }


                <div className="flex justify-between">
                    <p className="text-gray-800 text-lg">Gesamtbetrag</p>
                    <p className="text-gray-800 text-lg">{(Math.round(getGesammtBetragAfterDiscount() * 100) / 100)?.toFixed(2).split(".").join(",")} €</p>
                </div>
                {
                    getBasketTotal(basket) < 30 &&
                    <div className="bg-yellow-100 rounded-lg  p-3 flex justify-between text-gray-600 mt-3">
                        <p>Ausstehender Betrag: </p>
                        <p className="whitespace-nowrap">{(Math.round((30 - getBasketTotal(basket)) * 100) / 100).toFixed(2).split(".").join(",")} €</p>
                    </div>
                }
                <br/>
                <div className="w-full border-b">
                </div>
                <br/>
                {
                    props.displayDelivery && contactInformation.name &&
                    <div className="imageFadeIn">
                        <div className="flex justify-between">
                            <h1 className="text-2xl text-gray-800">Lieferung</h1>
                            <EditButton/>
                        </div>
                        <br/>
                        <DeliveryItem title="Adresse: "
                                      value={contactInformation.street + " " + contactInformation?.housenumber}/>
                        <DeliveryItem title="Abholung: "
                                      value={getFormattedDate(pickupDate, pickupTimeFrame) || ""}/>
                        <DeliveryItem title="Lieferung: "
                                      value={getFormattedDate(dropOffDate, dropOffTimeFrame) || ""}/>
                    </div>
                }
            </div>
            {props?.children}
        </div>
    );
};


export const DeliveryItem = ({title, value}) => {
    return (
        <div className="flex justify-between mb-4">
            <p className="text-lg text-gray-800">{title}</p>
            <p className="text-lg text-gray-500">{value}</p>
        </div>
    )
}

export const BasketItem = ({title, price, imageURL, count, onDeleted}) => {
    const [fallBackImage, setFallBackImage] = useState("");
    const location = useLocation();
    const noImageURL = "https://firebasestorage.googleapis.com/v0/b/droov-fbaef.appspot.com/o/static_images%2FScreenshot%202021-02-12%20at%2020.20.00.png?alt=media&token=5eed2acd-fc62-4114-90e8-99637a89e2b1"

    return (
        <div className="flex justify-between mx-auto items-center mb-4">
            <div className="flex space-x-3 transform transition-all duration-150 ease-out">
                <img
                    onError={() => setFallBackImage(noImageURL)}
                    className="rounded object-cover rounded-xl h-10 w-10"
                    src={fallBackImage || imageURL}
                    alt=""/>

                <div className="flex justify-start items-center">
                    <p className="mr-2 text-l  text-gray-800">{count}</p>
                    <p className="mr-2 text-l md:text-lg text-gray-500">x</p>
                    <p className="mr-2 text-l  text-gray-800">{title}</p>
                </div>
            </div>
            <div className="flex space-x-3">
                <p className="text-gray-500 text-lg whitespace-nowrap">{(Math.round(price * count * 100) / 100).toFixed(2).split(".").join(",")} €</p>
                {location.pathname !== "/confirm" &&
                <button className='focus:outline-none' onClick={() => onDeleted()}><DeleteButton/></button>}
            </div>
        </div>
    );
};

export default Basket
