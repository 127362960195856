import {useEffect} from "react"
import failureImg from "../assets/no_data_image.svg"

function FailurePage() {




    return (
        <div className="max-w-lg mx-auto">
            <br/>
            <div>
                <h1 className="text-gray-600 text-4xl text-center">Oops!</h1>
                <br/>
                <br/>
            </div>

            <br/>
            <div className="flex justify-center items-center">
                <img style={{height: "190px"}} src={failureImg} alt="success"/>
            </div>

            <br/>
            <br/>
            <h1 className="myTitle text-4xl text-center">Something went wrong</h1>

            <br/>
            <br/>

            <p className="text-center text-gray-500">
                Please try again with your operation and feel free to report us this issue. Our engineers will solve this issue soon.
            </p>


            <br/>
            <br/>
            <br/>

            <div className="md:flex md:space-x-3 space-y-3 md:space-y-0">
                <button  type="submit" form="adressForm" className="myButtonSecondary ">Back to ordering
                </button>

               {/* <button type="submit" form="adressForm" className="myButton ">Order tracking</button>*/}
            </div>

        </div>
    );
}

export default FailurePage;