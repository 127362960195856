import create from 'zustand'
import {persist} from "zustand/middleware"

export const useDiscount = create(persist(
    (set, get) => ({
        discountInformation: {},
        addDiscountInfo: (info) => set({discountInformation: info}),
        clearDiscountInfos: () => set({discountInformation: {}})
    }),
    {
        name: "droove_discount", // unique name
        getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
))
