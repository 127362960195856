import successImg from "../assets/check_green_icon.png"
import {products} from "../routesConfig";
import {useEffect} from "react";
import {useBasket} from "../GlobalStorage/warenkorbState";
import {useNotes} from "../GlobalStorage/notes";
import {useDiscount} from "../GlobalStorage/discount";

const selector = state => [state.basket, state.emptyBasket]
const selectorNotes = state => [state.notes, state.setNotes]
const discountSelector = state => [state.discountInformation, state.clearDiscountInfos]

function SuccessPage() {

  const [basket, emptyBasket] = useBasket(selector)
  const [notes, setNotes] = useNotes(selectorNotes)
  const [discountInformation, clearDiscountInfos] = useDiscount(discountSelector)

  useEffect(() => {
    // Google Tag Manager (For Kingsgard tracking of customer steps)
    /*window.dataLayer.push({'event': 'thank_you'});*/
    console.log("AFTER Successful payed the amount")
    clearDiscountInfos()
    setNotes("")
    emptyBasket()

  }, []);

  return (
    <div className="max-w-lg mx-auto">
      <br/>
      <div>
        <h1 className="textSuccess text-4xl text-center">Erfolgreich!</h1>
        <br/>
        <br/>
      </div>

      <br/>
      <div className="flex justify-center items-center">
        <img style={{height: "180px"}} src={successImg} alt="success"/>
      </div>

      <br/>
      <br/>
      <h1 className="myTitle text-4xl text-center">Danke, wir haben deine Bestellung erhalten</h1>

      <br/>
      <br/>

      <p className="text-center text-gray-500">
        Du hast von uns eine Bestätigungs E-Mail erhalten. Schau auch mal im Spam Ordner nach.
      </p>


      <br/>
      <br/>
      <br/>

      <div className="px-3 flex md:space-x-3 space-y-3 md:space-y-0">
        <a href={products} className="myButtonSecondary text-center ">Zuruck zu Reinio
        </a>

        {/* <button type="submit" form="adressForm" className="myButton ">Order tracking</button>*/}
      </div>

    </div>
  );
}

export default SuccessPage;