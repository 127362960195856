import firebase from 'firebase'

const config = {
    apiKey: "AIzaSyAspI6QIhd1v43vNR8V0wpJW4dL6pey0TQ",
    authDomain: "droov-fbaef.firebaseapp.com",
    databaseURL: "https://droov-fbaef.firebaseio.com",
    projectId: "droov-fbaef",
    storageBucket: "droov-fbaef.appspot.com",
    messagingSenderId: "93078866564",
    appId: "1:93078866564:web:bf2a67542dd66102ded0d1",
    measurementId: "G-JMRRRNNM3J",
};

let firebaseApp;
// Initialize Firebase if not done jet
if (!firebaseApp) {
    firebaseApp = firebase.initializeApp(config);
}
// Initialize Firebase

const storage = firebaseApp.storage();
const db = firebaseApp.firestore()
const auth = firebaseApp.auth()

export { storage, db as default, auth };

